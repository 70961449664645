import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AppContainerView from '../../components/AppContainerView';
import MobileHeader from '../../components/MobileHeader';
import MobileTemplate from '../../components/MobileTemplate';
import ModalPopup from '../../components/ModalPopup';
import { ProductGrade } from '../../data';
import useMomguideProductGrades from '../../hooks/useMomguideGrade';
import { RootState } from '../../state/rootStore';
import * as styles from '../../styles/productGradeExplain/productGradeExplain.module.css';

interface Props {
  closePress: () => void;
  navigation: any;
}

const BottomText = ({ text }: { text: string }) => {
  return (
    <div style={{ display: 'flex', marginBottom: 18 }}>
      <span style={{ color: '#898989', fontSize: 10 }}>•</span>
      <span style={{ color: '#898989', fontSize: 10 }}>{text}</span>
    </div>
  );
};

const Header = () => {
  return (
    <div className={styles.flatListHeader}>
      <div
        style={{
          borderBottom: 'solid 1px #dadada',
          paddingBottom: 28,
          marginBottom: 41,
        }}
      >
        <span className={styles.flatListHeaderText}>
          *국내에 유통되는 모든 생활화학제품, 화장품, 의약외품은{'\n'}
          정부에서 설정한 기준 및 배합한도에 따라 제조되기에,{'\n'}
          사용 방법에 따라 적정량 사용하면 <span className={styles.bold}>안전</span>
          합니다.{'\n'}
          하지만 부작용이 있을 수 있고, 피부 장벽이 약한 어린아이,{'\n'}
          민감한 분들의 특성을 고려하여 엄격한 기준을 적용했을 때,{'\n'}각 카테고리별로 제품의 함유성분에 따른 추천/비추천 제품 등을{'\n'}
          도출할 수 있었습니다.
        </span>
      </div>
      <span
        style={{
          marginBottom: 24,
          fontSize: 22,
          color: '#424242',
          fontWeight: 500,
        }}
      >
        성분에 따른 제품 등급
      </span>
    </div>
  );
};

const ProductGradeExplain = ({ closePress, navigation }: Props) => {
  const [isMomguideDetailInfo, setIsMomguideDetailInfo] = useState(false);
  const momguideDetailInfoStartDate = useRef(new Date());
  const momguideDetailInfoEndDate = useRef(new Date());
  const appType = useSelector((state: RootState) => state.appType);

  const momguideProductGrades = useMomguideProductGrades();

  const RenderItem = ({ item, index }: { item: ProductGrade; index: number }) => {
    return (
      <li
        style={{
          display: 'flex',
          borderBottom: 'solid 1px #dadada',
        }}
      >
        <div
          style={{
            width: 57,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#f0f9f7',
            minHeight: 94,
            paddingTop: 7,
          }}
        >
          <img style={{ width: 50, height: 50, objectFit: 'contain' }} src={item.image3} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginLeft: 11,
            marginTop: 15,
            paddingBottom: 20,
          }}
        >
          <span
            style={{
              color: item.color,
              marginBottom: 11,
              fontSize: 13,
              fontWeight: 'bold',
            }}
          >
            {item.title}
          </span>
          <span className={styles.explainText}>{item.explain}</span>
          {item.subexplain && <span className={styles.subexplainText}>{item.subexplain}</span>}
          {index === 0 && (
            <span className={styles.detailLearnText} onClick={toggleMomguideDetailInfo}>
              자세히 알아보기
            </span>
          )}
        </div>
      </li>
    );
  };

  const toggleMomguideDetailInfo = () => {
    if (!isMomguideDetailInfo) {
      momguideDetailInfoStartDate.current = new Date();
      // amplitudeLog("맘가이드 등급 - 맘가이드인증 자세히 알아보기 열기")
    } else {
      momguideDetailInfoEndDate.current = new Date();
      const duration = Math.round((momguideDetailInfoEndDate.current.getTime() - momguideDetailInfoStartDate.current.getTime()) / 1000);
      const logDate = {
        durationSeconds: duration,
      };
      // amplitudeLog("맘가이드 등급 - 맘가이드인증 자세히 알아보기 닫기", logDate)
    }
    setIsMomguideDetailInfo(!isMomguideDetailInfo);
  };

  return (
    <AppContainerView>
      <MobileTemplate>
        <div className={styles.container}>
          <MobileHeader title={appType === 'mom' ? '맘가이드 등급' : '펫가이드 등급'} />

          <div className={styles.flatList}>
            <Header />
            <ul>
              {momguideProductGrades.map((item, index) => {
                return <RenderItem item={item} index={index} />;
              })}
            </ul>

            <>
              <div className={styles.flatListFooter} style={{ marginTop: 44 }}>
                <BottomText text={'본 등급은 함유 성분을 기준으로 도출된 결과이며, 실제 제품의 성능 및 사용감과는 무관할 수 있습니다.'} />
                <BottomText
                  text={
                    '위 등급은 제품 자체의 절대적인 위해성 판단기준은 아니며, 제품 함유 성분에 따라 각 카테고리 내에서 상대적으로 책정되었습니다.'
                  }
                />
                <BottomText
                  text={
                    '위 등급은 함량 및 사용방식에 따른 노출량과 개개인의 특성까지 고려된 실제 위험 수준이 아니며, 인체에 대한 잠재적인 우려 수준을 고려하여 도출되었습니다.'
                  }
                />
                <BottomText
                  text={
                    '위 등급은 제품 성분 변경 혹은 성분의 추가적인 유해성 데이터 수집에 따라 달라질 수 있으며, 제품 구매 시 참고용으로 사용해주시기 바랍니다.'
                  }
                />
                <BottomText text={'위 등급 설정에 관한 기준 및 구체적인 방법론은 맘가이드 자주 묻는 질문(FAQ)에 게시되어 있습니다.'} />
              </div>
            </>
          </div>
          <ModalPopup isVisible={isMomguideDetailInfo} setIsVisible={setIsMomguideDetailInfo} title="맘가이드 클린 제품이란?">
            <div style={{ background: '#ffffff' }}>
              <img
                style={{
                  width: '100%',
                  imageRendering: '-webkit-optimize-contrast',
                }}
                src={'/images/popup/momguide_info_modal1.jpg'}
              />
              <img
                style={{
                  width: '100%',
                  imageRendering: '-webkit-optimize-contrast',
                }}
                src={'/images/popup/momguide_info_modal2.jpg'}
              />
              <img
                style={{
                  width: '100%',
                  imageRendering: '-webkit-optimize-contrast',
                }}
                src={'/images/popup/momguide_info_modal3.jpg'}
              />
            </div>
          </ModalPopup>
        </div>
      </MobileTemplate>
    </AppContainerView>
  );
};

export default ProductGradeExplain;
