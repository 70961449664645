// extracted by mini-css-extract-plugin
export var bold = "productGradeExplain-module--bold--765df";
export var container = "productGradeExplain-module--container--1ff08";
export var detailLearnText = "productGradeExplain-module--detailLearnText--24c4e";
export var explainText = "productGradeExplain-module--explainText--ff7cd";
export var flatList = "productGradeExplain-module--flatList--6ae41";
export var flatListFooter = "productGradeExplain-module--flatListFooter--f1173";
export var flatListFoterText = "productGradeExplain-module--flatListFoterText--10ac6";
export var flatListHeader = "productGradeExplain-module--flatListHeader--3f7d3";
export var flatListHeaderText = "productGradeExplain-module--flatListHeaderText--431e6";
export var headerContainer = "productGradeExplain-module--headerContainer--d26a4";
export var headerLeftView = "productGradeExplain-module--headerLeftView--706f4";
export var subexplainText = "productGradeExplain-module--subexplainText--23696";